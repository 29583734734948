import { React } from "react";
import { useState } from "react";
import {
  Text,
  Flex,
  Button,
  IconButton,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import styles from "./NavBar.module.css";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {
  const [display, changeDisplay] = useState("none");

  return (
    <Flex className={styles.container}>
      <Grid templateColumns="repeat(3, 1fr)" className={styles.grid}>
        <GridItem alignItems="center" className={styles.logoGrid}>
          <a href="/">
            <img className={styles.logoImg} src="/logo_white.png" />
          </a>
        </GridItem>
        <GridItem className={styles.navGrid}>
          <Flex className={styles.navSection}>
            <a href="/">
              <Text className={styles.navText}>HOME</Text>
            </a>
            <HashLink to="/about">
              <Text className={styles.navText} cursor="pointer">
                ABOUT
              </Text>
            </HashLink>

            <a href="/product">
              <Text className={styles.navText}>PRODUCTS</Text>
            </a>

            {/* <a href="/works">
              <Text className={styles.navText}>WORKS</Text>
            </a> */}
          </Flex>
        </GridItem>
        <GridItem display="flex" justifyContent="flex-end" alignItems="center">
          <a href="/contact">
            <Button className={styles.contactButton}>CONTACT US</Button>
          </a>
        </GridItem>
      </Grid>

      {/* Hamburger Icon  */}
      <IconButton
        className={styles.hamburgerIcon}
        aria-label="Open Menu"
        variant="ghost"
        colorScheme="#e93d27"
        size="lg"
        mr={1.5}
        icon={<HamburgerIcon color="white" />}
        onClick={() => changeDisplay("flex")}
      />

      <Flex
        className={styles.mobile}
        zIndex={200}
        top="0"
        left="0"
        display={display}
      >
        <Flex justify="flex-end">
          <IconButton
            aria-label="Close Menu"
            mt={2}
            mr={2}
            size="lg"
            icon={<CloseIcon />}
            onClick={() => changeDisplay("none")}
          />
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
          gap={4}
          justifyContent="center"
        >
          <a href="/">
            <Text className={styles.navTextMobile}>HOME</Text>
          </a>
          <HashLink to="/about">
            <Text className={styles.navTextMobile}>ABOUT</Text>
          </HashLink>

          <a href="/product">
            <Text className={styles.navTextMobile}>PRODUCTS</Text>
          </a>

          {/* <a href="/works">
            <Text className={styles.navTextMobile}>WORKS</Text>
          </a> */}
        </Flex>
      </Flex>
    </Flex>

    // <Flex className={styles.container} boxShadow="md" backgroundColor="#e93d27">
    //   <Link href="/">
    //     <img className={styles.logoImg} src="/logo_white.png" />
    //   </Link>
    //   <Flex top="1rem" right="1rem" align="center">
    //     <Flex display={["none", "none", "flex", "flex"]} gap="25px">
    //       <a href="/">
    //         <Text color="white" as="b" fontSize="lg">
    //           Home
    //         </Text>
    //       </a>

    //       <HashLink to="/home#about">
    //         <Text color="white" as="b" fontSize="lg" cursor="pointer">
    //           About
    //         </Text>
    //       </HashLink>

    //       <a href="/product">
    //         <Text color="white" as="b" fontSize="lg">
    //           Product
    //         </Text>
    //       </a>

    //       <a href="/careers">
    //         <Text color="white" as="b" fontSize="lg">
    //           Careers
    //         </Text>
    //       </a>
    //       <a href="/contact">
    //         <Text color="white" as="b" fontSize="lg">
    //           Contact Us
    //         </Text>
    //       </a>
    //     </Flex>

    //     {/* Hamburger Icon  */}
    //     <IconButton
    //       className={styles.hamburgerIcon}
    //       aria-label="Open Menu"
    //       variant="ghost"
    //       colorScheme="#e93d27"
    //       size="lg"
    //       mr={1.5}
    //       icon={<HamburgerIcon color="white" />}
    //       onClick={() => changeDisplay("flex")}
    //     />
    //   </Flex>

    //   {/* Mobile Version  */}
    //   <Flex
    //     className={styles.mobile}
    //     bgColor="gray.50"
    //     zIndex={200}
    //     top="0"
    //     left="0"
    //     display={display}
    //   >
    //     <Flex justify="flex-end">
    //       <IconButton
    //         aria-label="Close Menu"
    //         mt={2}
    //         mr={2}
    //         size="lg"
    //         icon={<CloseIcon />}
    //         onClick={() => changeDisplay("none")}
    //       ></IconButton>
    //     </Flex>
    //     <Flex flexDir="column" align="center">
    //       <a href="/">
    //         <Button variant="ghost" colorScheme="white">
    //           Home
    //         </Button>
    //       </a>

    //       <HashLink to="/home#about">
    //         <Button variant="ghost" colorScheme="white">
    //           About
    //         </Button>
    //       </HashLink>

    //       <a href="/product">
    //         <Button variant="ghost" colorScheme="white">
    //           Product
    //         </Button>
    //       </a>

    //       <a href="/careers">
    //         <Button variant="ghost" colorScheme="white">
    //           Careers
    //         </Button>
    //       </a>

    //       <a href="/contact">
    //         <Button variant="ghost" colorScheme="white">
    //           Contact Us
    //         </Button>
    //       </a>
    //     </Flex>
    //   </Flex>
    // </Flex>
  );
};

export default NavBar;
