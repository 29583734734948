import React from "react";
import { Flex, Text, Grid, GridItem, Box } from "@chakra-ui/react";
import styles from "./ProductPage.module.css";
import Footer from "../components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

const HomePage = () => {
  return (
    <Flex className={styles.container}>
      <Flex className={styles.headerContainer}>
        <img className={styles.kuriaBarImg} src="kuria_bar_3.png" />
        <div className={styles.backgroundOverlay} />
        <img className={styles.kuriaBarGrey} src="kuria_bar_abu.png" />
        <Flex className={styles.headerTextContainer}>
          <Text className={styles.headerTitleText}>KURIA </Text>
          <Text className={styles.headerTitleText}>REBAR</Text>
        </Flex>
      </Flex>

      <Flex className={styles.infoSection}>
        <Text className={styles.infoText}>
          Kuria Rebar offers superior performance, reduced maintenance, and
          environmental benefits, making it a
        </Text>
        <Text className={styles.infoText2} fontWeight="500">
          key component of the future of sustainable construction.
        </Text>
      </Flex>

      <Flex className={styles.sizesSection}>
        <Text className={styles.sizesTitle}>Available in Various Sizes</Text>
        <Text className={styles.sizesSubTitle}>
          Diameter Kuria Bar bervariasi dari 4mm hingga 16 mm,
        </Text>
        <Text className={styles.sizesSubTitle}>
          dan dapat dipesan hingga panjang{" "}
          <span style={{ color: "#e93d27", fontWeight: "600" }}>100m</span>
        </Text>
        <Flex className={styles.sizesContainer}>
          <img className={styles.sizesImg} src="BarSizes.jpg" />
          {/* <Flex className={styles.sizesTextContainer}>
            <Text className={styles.sizesText}>16mm</Text>
            <Text className={styles.sizesText}>13mm</Text>
            <Text className={styles.sizesText}>12mm</Text>
            <Text className={styles.sizesText}>10mm</Text>
            <Text className={styles.sizesText}>8mm</Text>
            <Text className={styles.sizesText}>6mm</Text>
            <Text className={styles.sizesText}>5mm</Text>
            <Text className={styles.sizesText}>4mm</Text>
          </Flex> */}
        </Flex>
      </Flex>

      {/* Comparison Section  */}
      <Flex className={styles.compareSection}>
        <Text className={styles.compareTitleText}>
          Kuria Bar <span className={styles.compareSubTitleText}>vs</span> Steel
        </Text>
        <img className={styles.compareImg} src="comparison_product.png" />
        <div className={styles.comparisonBoxContainer}>
          <ComparisonBox
            kuriaText="Tahan terhadap korosi dan bahan kimia"
            title="Corrosion"
            steelText="Rentan terhadap karat dan korosi, terutama di lingkungan yang mengandung garam"
            className={styles.comparisonBox1}
          />
          <ComparisonBox
            kuriaText="1/4 bobot besi sehingga biaya transportasi rendah dan peningkatan efisiensi kerja"
            title="Weight"
            steelText="Berat unit tinggi sehingga biaya transportasi mahal"
          />
          <ComparisonBox
            kuriaText="Kekuatan tarik sekitar 3x lebih kuat dari tulangan baja"
            title="Daya Tarik"
            steelText="Kekuatan tarik rendah dibandingkan dengan material lain"
          />
          <ComparisonBox
            kuriaText="Biaya lebih rendah daripada tulangan baja"
            title="Cost"
            steelText="Harga tulangan baja dapat fluktuasi dan naik"
          />
          <ComparisonBox
            kuriaText="Tidak menghantarkan panas dan listrik"
            title="Conduction"
            steelText="Konduktif listrik dan panas serta tidak cocok untuk bangunan dengan sensitifitas electromagnetic"
          />
        </div>
      </Flex>

      {/* Scientifically Proven Section  */}
      <Flex className={styles.scientificSection}>
        <Text className={styles.scientificTitle}>Scientifically Proven</Text>
        <Flex className={styles.scientificCardUpper}>
          <Flex className={styles.scientificCard}>
            <Text className={styles.scientificCardTitle}>Kuria Rebar</Text>
            <Text className={styles.scientificCardText}>
              Kuat tarik lebih dari 1000 N/mm2
            </Text>
            <img className={styles.scientificCardImg} src="5_Table up 1.png" />
          </Flex>
          <Flex className={styles.scientificCard}>
            <Text className={styles.scientificCardTitle}>Besi Beton</Text>
            <Text className={styles.scientificCardText}>
              Kuat tarik 280 - 420 N/mm2
            </Text>
            <img className={styles.scientificCardImg} src="5_Table up 2.png" />
          </Flex>
        </Flex>
        <Flex className={styles.scientificCard3}>
          <Text className={styles.scientificCardTitle}>
            Kuria Rebar vs Besi Beton
          </Text>
          <Text className={styles.scientificCardText}>
            Perbandingan kekuatan dalam penggunaan dalam slab beton
          </Text>
          <img className={styles.scientificCardImg} src="5_Table below.png" />
        </Flex>
      </Flex>

      {/* Application Section  */}
      <Flex className={styles.applicationSection}>
        <Text className={styles.applicationHeader}>Application</Text>
        <Grid className={styles.applicationImgContainer}>
          <GridItem rowSpan={2} colSpan={1}>
            <Flex className={styles.applicationTextContainer}>
              <Text className={styles.applicationText}>INDUSTRIAL</Text>
              <Text className={styles.applicationSubText}>
                Chemical plants | Power plants | Oil and gas platforms
              </Text>
            </Flex>

            <img
              className={styles.applicationImg}
              src="6_Application 1.png"
              alt="Application"
            />
          </GridItem>

          <GridItem rowSpan={2} colSpan={1}>
            <img className={styles.applicationImg} src="6_Application 2.png" />
            <Flex className={styles.applicationTextContainer}>
              <Text className={styles.applicationText}>BUILDINGS</Text>
              <Text className={styles.applicationSubText}>
                Foundation | Columns and Beams | Slabs | Walls
              </Text>
            </Flex>
          </GridItem>
          <GridItem rowSpan={2} colSpan={1}>
            <Flex className={styles.applicationTextContainer}>
              <Text className={styles.applicationText} width="80%">
                CONSTRUCTION
              </Text>
              <Text className={styles.applicationSubText}>
                Bridges | Buildings | Parking garages | Marine structures |
                Coastal structures
              </Text>
            </Flex>
            <img className={styles.applicationImg} src="6_Application 3.png" />
          </GridItem>
          <GridItem rowSpan={2} colSpan={1}>
            <img className={styles.applicationImg} src="6_Application 4.png" />
            <Flex className={styles.applicationTextContainer}>
              <Text className={styles.applicationText}>INFRASTRUCTURE</Text>
              <Text className={styles.applicationSubText}>
                Water treatment plants | Sewage treatment plants | Tunnels |
                Pipelines
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>

      {/* Our Projects Section  */}
      <Flex className={styles.projectsSection}>
        <Text className={styles.projectsTitle}>Our Successful Projects</Text>
        <ProjectsCarousel />
      </Flex>

      {/* Try Kuria Now Banner  */}
      <Flex className={styles.callOutBanner}>
        <Text className={styles.callOutText}>Have a project in mind?</Text>
        <CallOutButton />
      </Flex>

      <Footer />
    </Flex>
  );
};

export default HomePage;

function ComparisonBox({ kuriaText, title, steelText, className }) {
  return (
    <Flex className={`${styles.comparisonBox} ${className || ""}`}>
      <Text className={styles.comparisonText}>{kuriaText}</Text>
      <Text className={styles.comparisonHeading}>{title}</Text>
      <Text className={styles.comparisonText}>{steelText}</Text>
    </Flex>
  );
}

function CallOutButton() {
  return (
    <Box
      as="button"
      className={styles.callOutButton}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _hover={{ bg: "#ebedf0" }}
      _active={{
        bg: "#dddfe2",
        transform: "scale(0.98)",
        borderColor: "#bec3c9",
      }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
    >
      GET THEM NOW
    </Box>
  );
}

function ProjectsCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        <div className={styles.slide}>
          <img className={styles.carouselImg} src="Frame 497.png" />
        </div>
        <div className={styles.slide}>
          <img className={styles.carouselImg} src="Frame 497.png" />
        </div>
        <div className={styles.slide}>
          <img className={styles.carouselImg} src="Frame 497.png" />
        </div>
      </Slider>
    </div>
  );
}
