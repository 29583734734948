import React from "react";
import styles from "./NewsPage.module.css";
import Footer from "../components/Footer";
import { Flex, Text, Divider } from "@chakra-ui/react";

const NewsPage = () => {
  return (
    <Flex flexDirection="column">
      {/* News Title */}
      <Flex position="relative">
        <img className={styles.img} src="news.jpg" />
        <Text
          className={styles.newsTitle}
          as="b"
          fontSize="9xl"
          color="#e93d27"
        >
          {" "}
          NEWS
        </Text>
      </Flex>

      <Flex paddingTop="48px" paddingX="120px" flexDirection="column">
        <Text fontSize="3xl">Latest News</Text>
        <Divider orientation="horizontal" marginY="32px" />

        <a href="https://www.newvision.co.ug/category/news/fiberglass-bars-reduce-the-cost-of-constructi-145124">
          <img className={styles.newsImg} src="cacing.jpeg" />
          <Text fontSize="2xl">
            SpongeBob SquarePants and Sandy Cheeks Face Terrifying Encounter
            with Enormous Worm!
          </Text>
          <Text fontSize="xl" color="blackAlpha.500" paddingTop="16px">
            SpongeBob SquarePants and Sandy Cheeks narrowly escape a massive
            worm's pursuit in a thrilling adventure at Jellyfish Fields.
          </Text>
          <Text fontSize="lg" color="blackAlpha.500" paddingTop="16px">
            20 June 2020
          </Text>
        </a>

        <Divider orientation="horizontal" marginY="32px" />
        <a href="https://www.newvision.co.ug/category/news/fiberglass-bars-reduce-the-cost-of-constructi-145124">
          <img className={styles.newsImg} src="cacing.jpeg" />
          <Text fontSize="2xl">
            SpongeBob SquarePants and Sandy Cheeks Face Terrifying Encounter
            with Enormous Worm!
          </Text>
          <Text fontSize="xl" color="blackAlpha.500" paddingTop="16px">
            SpongeBob SquarePants and Sandy Cheeks narrowly escape a massive
            worm's pursuit in a thrilling adventure at Jellyfish Fields.
          </Text>
          <Text fontSize="lg" color="blackAlpha.500" paddingTop="16px">
            20 June 2020
          </Text>
        </a>

        <Divider orientation="horizontal" marginY="16px" />
        <a href="https://www.newvision.co.ug/category/news/fiberglass-bars-reduce-the-cost-of-constructi-145124">
          <img className={styles.newsImg} src="cacing.jpeg" />
          <Text fontSize="2xl">
            SpongeBob SquarePants and Sandy Cheeks Face Terrifying Encounter
            with Enormous Worm!
          </Text>
          <Text fontSize="xl" color="blackAlpha.500" paddingTop="16px">
            SpongeBob SquarePants and Sandy Cheeks narrowly escape a massive
            worm's pursuit in a thrilling adventure at Jellyfish Fields.
          </Text>
          <Text
            fontSize="lg"
            color="blackAlpha.500"
            paddingTop="16px"
            paddingBottom="64px"
          >
            20 June 2020
          </Text>
        </a>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default NewsPage;
