import React from "react";
import styles from "./ClientPage.module.css";
import Footer from "../components/Footer";
import { Flex, Text, Divider } from "@chakra-ui/react";

const ClientPage = () => {
  return (
    <Flex flexDirection="column">
      <Text>testt</Text>
    </Flex>
  );
};

export default ClientPage;
