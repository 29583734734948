import React from "react";
import ReactGA from "react-ga4";

export const initGA = () => {
  const trackingId = process.env.REACT_APP_TRACKING_ID;
  if (!trackingId) {
    console.error("Require GA_MEASUREMENT_ID");
    return;
  }
  ReactGA.initialize(trackingId);
};

export const logPageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};
